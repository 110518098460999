@font-face {
  font-family: 'G&B-Headline';
  src: url('fonts/G&B-Headline.woff2') format('woff2'),
    url("fonts/G&B-Headline.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'G&B-BodyCopy';
  src: url('fonts/G&B-BodyCopy.woff2') format('woff2'),
    url("fonts/G&B-BodyCopy.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'G&B-BodyCopyHighlight';
  src: url('fonts/G&B-BodyCopyHighlight.woff2') format('woff2'),
    url("fonts/G&B-BodyCopyHighlight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'G&B-HeadlineItalic';
  src: url('fonts/G&B-HeadlineItalic.woff2') format('woff2'),
    url("fonts/G&B-HeadlineItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'G&B-BodyCopyItalic';
  src: url('fonts/G&B-BodyCopyItalic.woff2') format('woff2'),
    url("fonts/G&B-BodyCopyItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


/* Importing G&B fonts ends */
:root {
  font-size: 16px;
}

.header.navbar{
  height: 59px !important;
  background-color: #ffffff !important;
  width: 100%;
  float: right;
  border-bottom: 1px solid #F1F1ED !important;
  box-shadow: none;
}

.openHeadNav.navbar {
  width: 85% !important;
}
.closeHeadNav.navbar {
  width: 95% !important;
}

.UserLogin.MenuHead {
  color: #810055 !important;
  font-size: 18px !important;
  font-family: "G&B-BodyCopyHighlight" !important;
  padding-left: 10px !important;
}

.UserLogin.ms-auto{
  color: #333333 !important;
  font-size: 16px;
  font-family: "G&B-Headline";
}

.user-logo {
  background: #f9f2f6 0% 0% no-repeat padding-box;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  top: 8px;
}

.user-logo span{
  color: #810055;
  font-family: "G&B-Headline";
  font-size: 16px;
}

.navbar-toggler{
  background-color:white !important;
  width: 30px !important;
  height: 30px !important;
  padding:0 !important;
  border-radius: 100px !important;
}
#basic-navbar-nav {
  border-top: 1px solid #F1F1ED !important;
}

.sidenavbar .container{
  padding-right: 0;
  width:100% !important;
}

.sidenavbar .arrow{
  display: block !important;
  margin-left: 15px  !important;
  background: url("../src/assets/img/icons/Hamburger.svg");
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  width: 32px !important;
  height: 32px !important;
  border: none !important;
  float: right;
  margin-right: 5% !important;
}

.navbar-toggler-icon{
  width: 32px !important;
  height: 32px !important;
  background-image: url("assets/img/icons/Hamburger.svg") !important;
  border: none !important;
}

button.navbar-toggler.collapsed{
  border: none !important;
  width: 32px !important;
  height: 32px !important;
}

.MenuHead {
  font-size: 150%;
  font-weight: bold;
}

.a {
  width:90% ;
  align-items:stretch;
  padding: 3%;
  height: 100%;
  overflow-y: auto;
}
html, body {
  height: 100%;
}
/* footer */

.footer {
  border-bottom: none;
  box-shadow: 1px 3px 0 1px #CCCCCC;
  bottom: 0;
  height: 10%;
}

/* side bar */
.sidenavbar {
  height: 100% !important;
  width: 15% !important;;
  position: fixed !important; 
  z-index: 1 !important;
  top: 10 !important; 
  left: 0 !important;
  background-color:#ffffff !important;
  padding-top: 1% !important;
  /* padding-right: .5rem;
  padding-left: .5rem; */
  border-right: 1px solid #f1f1ed !important
}

#basic-navbar-nav{
width: 100%;
}

#basic-navbar-nav .mx-auto{
  margin-right:0 !important;
}
.opensidenavbar {
  display: block !important;
  height: 100% !important;
  width: 15% !important;;
  position: fixed !important; 
  z-index: 1 !important;
  top: 10 !important; 
  left: 0 !important;
  /* background-color:#5FB233 !important; */
  padding-top: 1% !important;
  transition: width 1s;
}

.main{
  height: 100% !important;
  padding: 3% !important;
  overflow-y: auto !important;
}

/*on close*/
.closesidenavbar {
  /* display: none  !important; */
  width: 5% !important;
  display: block !important;
  transition: width 1s;
}

.closesidenavbar div#changepasswordtab{
  font-size: 12px !important;
}

.closesidenavbar .arrow{
  margin-right: 25% !important;
}


.MenuLink{
  display:none;
  }

.activeMenuLink{
  display:block;
  padding-left: 10px !important;
  border-radius: 0% !important;
}

a.dropdown-btn.nav-link{
  font-size: 16px !important;
  border-radius: 0% !important;
  font-family: "G&B-BodyCopy" !important;
}

div#example-collapse-text{
  font-size: 14px !important;
  font-family: "G&B-BodyCopy" !important;
}



/* end side bar */

.openmain {
  margin-left: 15% !important;
  width:85% ; 
}

span.text-primary {
  color: #333333 !important;
}

span.text-primary.pwdText {
  font-size: 14px !important;
  font-family: "G&B-BodyCopy" !important;
  color: #707070 !important;
}

.col-6.mb-2 h2{
  font-family: "G&B-BodyCopyHighlight" !important;
  font-size: 18px !important;
  padding: 16px !important;
  color: #333333 !important;
}

/* Page footer */
.openfooter {
  margin-left:20% !important;
}

 .closemain {
  width:90% ;
  margin-left: 5% !important;
}

.closefooter .container {
  margin-left: 5% !important;
  width: 100%;
}

/* Style the sidenavbar links and the dropdown button */
.sidenavbar a,.sidenavbar .nav-link, .sidenavbar .dropdown-btn {
  /* padding: 6px 8px 6px 16px !important; */
  text-decoration: none !important;
  /* font-size: 20px !important; */
  color: #707070 !important;
  display: block !important;
  border: none !important;
  background: none !important;
  width: 100% !important;
  text-align: left !important;
  cursor: pointer !important;
  outline: none !important;
  padding-left:0 !important;
  padding-right:.5rem !important;
  
  /* padding-left: 0.5em; */
  /* padding-left: 2% !important; */
}

.nav-link.active, .nav-link:hover{
  border-radius: 0%!important;
}

/* On mouse-over */
.sidenavbar a:hover, .sidenavbar .nav-link:hover, .sidenavbar .dropdown-btn:hover {
  background-color:#f9f2f6  !important ;
  color: #810055 !important;
}
input[type='file'] {
  color: rgba(0, 0, 0, 0)
}

/* Add an active class to the active dropdown button */
.sidenavbar .active {
  background-color:#f9f2f6  !important ;
  color: #810055 !important;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.menu {
  /* background-color: #262626 !important ; */
  padding-left: 6% !important;
}


/* Header */
.header {
  background: #d02871;
  z-index: 1000;
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.header .container{
  /* width: 100% !important; */
  max-width: 100%;
}
.the-table {

  table-layout: fixed;

  word-wrap: break-word;

  overflow: hidden;

}


.wrapper {
  width:100%;
  align-items:stretch;
}
/*header end*/

/* slider start */
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #04AA6D;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

div .container{
  padding-left: 0% !important;
}

.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}
/* slider end */


select {
  border: 1px solid #f1f1ed;
  font-size: 14px;
  padding: 0px 4px;
  font-family: 'G&B-BodyCopy';
}


@media screen and (max-width:991px){
  .sidenavbar .arrow{
    display:none !important;
  }
  .header .navbar-toggler{
    display:block !important;
  }
  .dot {
    height: 25px;
    width: 25px;
    background-color: #33C037;
    border-radius: 50%;
    display: inline-block;
  }

  .center {
    margin: auto;
    width: 60%;
    border: 3px solid #33C037;
    padding: 10px;
  }
  
  .opensidenavbar {
    max-width:100% !important;
    width:100%;
    position: relative !important;
    padding-left: 2%;
    display:block !important;
    height: 100% !important;
    width:100% !important;
    opacity: 1 !important;
    transition: height 1s, opacity 1s;
  }  
  .sidenavbar .container{
    padding-left: 0;
    width:100% !important;
  }
  .closesidenavbar {
    display:none !important;
    opacity: 0 !important;
    height:0 !important;
    transition: height 1s, opacity 1s;
  }
  
  .navbar-toggler-icon {
    color: white !important;
  }
  .header .container{
    /* navbar-toggler */
    margin-left: 0 !important;
  }

  .sidenavbar a,.sidenavbar .nav-link, .sidenavbar .dropdown-btn {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
  }
  .main {
    margin-left: 0 !important;
  }
  .footer {
    margin-left: 0 !important;
    width: 100%;
  }
  .UserLogin{
    display:none;
  }

  .glyphicon-calendar
  {
      font-size: 15pt;
  }
  .input-group
  {
      width: 180px;
      margin-top:30px;
  }

  .openHeadNav.navbar {
    width: 100% !important;
  }
  
  
}


/*  Login CSS  */


.logoHeader {
  padding: 0px 6rem !important;
  border: 1px solid #F1F1ED !important;
  position: absolute;
  z-index: 9999;
  background-color: white;
  height: 56px !important;
  margin: 0 !important;
  width: 100%;
}

.headerLink {
  font-size: 0.75rem !important; 
  color: #810055 !important;
}

.loginBg{
  background: url('assets/img/Change-Management-System.jpg') no-repeat;
  background-position: 0 0 !important;
  background-size: cover;
  height: 100vh;
}

.loginForm {
  padding: 8rem 6rem !important;
  font-size: 1rem !important;
}

.loginForm a {
  text-decoration: none;
}

.loginForm h1 {
  font-size: 3.75rem !important; 
  font-family: 'G&B-Headline', sans-serif !important;
}

.loginForm h5 {
  font-size: 1.25rem !important; 
  font-family: 'G&B-Headline', sans-serif !important;
  color: #707070 !important;
}

.loginForm button {
  padding:5px 16px !important;
  border: 0px !important; 
  box-shadow: none; 
  background-color: #810055 !important; 
  color: #fff !important; 
  font-size: 1rem !important; 
  font-family: 'G&B-BodyCopyHighlight', sans-serif !important;
  height: 38px !important;
}

.loginForm button:hover {
  background-color: #810055 !important;
  color: #fff !important;
}

.godrejPurple{
  color: #810055 !important;
}
.footerLinks {
  /* padding-top: 50% !important; */
  display: flex;
  justify-content: center;
}
.footerLinks a {
  font-size: 0.75rem !important; 
  color: #707070 !important;
  bottom: 1% !important;
  position: relative;
}

.footerLinks span {
  margin: 0px 1rem !important; 
  font-size: 0.75rem !important; 
}

@media (max-width: 450px){
  .headerLink { 
    margin-left: 25%; 
    margin-right: -35% !important;
  }
} 

@media (max-width: 576px) {
  body {
      --bs-body-font-size: 14px;
      --bs-body-font-weight: 400;
  }

  .loginForm {padding: 3rem;}

  .loginBg {display: none;}

  .loginForm h1{ font-size: 1.25rem !important }
}

@media (max-width: 768px) {
  .loginForm {padding: 3rem;}

  .loginBg {display: none;}

  .logoHeader {
      padding: 0.5rem 1.5rem 1rem 1.5rem;
      border-bottom: 1px solid #F1F1ED;
  }

  .loginForm h1{ font-size: 1.5rem !important }

}
@media (max-width: 992px) {
  .loginForm {padding: 4rem;}
  .loginForm h1{ font-size: 2rem !important }
}

@media (max-width: 1200px) {
.loginForm{padding: 4rem 3rem;}
.loginForm h1{ font-size: 2.25rem !important }
}

@media (max-width: 1400px) {
  .loginForm h1{ font-size: 2.75rem !important }
}

@media (max-width: 1920px) {
  .loginForm {padding: 6rem 4rem;}
}

/*  logbook form */

.LogbookForm .text-primary {
  font-family: 'G&B-BodyCopyHighlight' !important;
  font-size: 18px !important;
  padding: 16px !important;
}

thead.table-head th {
  color: #ffffff !important;
  background-color: #707070 !important;
  font-family: 'G&B-BodyCopyHighlight' !important;
  font-size: 14px !important;
}

tbody.table-body td {
  font-family: 'G&B-Headline'!important;
  color: #707070 !important;
  font-size: 14px !important;
}

/* Button {
  background-color: #810055 !important;
  color: #ffffff !important;
  border-color: #810055 !important;
} */

.btn-primary,
.btn-success {
  background-color: #810055 !important;
  color: #ffffff !important;
  border-color:  #810055 !important;
  font-size: 14px !important;
  border-radius: 20px !important;
  padding: 5px 16px !important;
  height: 32px !important;
}

button.btn.btn-outline-info{
  border-color: #F1F1ED !important;
  color: #707070 !important;
  background: #fff !important;
  font-size: 14px !important;
  border-radius: 20px !important;
  height: 32px !important;
  padding: 5px 16px !important;
}

.nav-link {
  color: #707070 !important;
  font-family: "G&B-BodyCopy" !important;
  font-size: 14px !important;
}

button.nav-link.active{
  color: #810055 !important;
  background-color: #F9F2F6 !important;
  font-family: "G&B-BodyCopyHighlight" !important;
  border-radius: 5px 5px 0px 0px !important;
  border-color: #f9f2f6 !important;
}

button.nav-link:hover{
  background-color: #f9f2f6 !important;
  border-radius: 5px 5px 0px 0px !important;
}

button .nav-tabs .nav-link {
  border-color: #F1F1ED !important;
}

.nav-tabs {
  --bs-nav-tabs-border-color: none !important;
}

button.btn.btn-outline-success.btn-sm,
button.btn.btn-danger.btn-lg {
  border-color: #810055 !important;
  color: #810055 !important;
  background: none !important;
  height: 32px !important;font-size: 14px !important;
  border-radius: 20px !important;
  padding: 5px 16px !important;
}
span.float-end.fa.fa-arrow-right {
    color: #707070 !important;
}
 
 
.col-5.mb-1 h2 {
    font-size: 18px;
    color: #333;
}
